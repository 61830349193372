






























































































































































































































































































































































































































































































































































#rc-c * {
  font-family: 'Open Sans', sans-serif;
  color: var(--color-text-primary);
  background-color: var(--color-background);
}

h1 {
    margin-top: 50px;
}

#rc-input-c input {
    border: 1px solid var(--color-background-outline);
    border-radius: 4px;
    height: 36px;
    padding: 10px;
    width: 155px;
    display: block;
    font-size: 1rem;
}

#rc-input-submit-button {
    background-color: var(--color-accent-primary);
    color: var(--color-typography-on-accent);
    display: inline-block;
    padding: 12px 10px;
    text-align: center;
    border-radius: 1500px;
    cursor: pointer;
}

.rc-section-title {
    color: var(--color-typography-on-inactive);
}

.material-input {
    width: 250px;
    margin-bottom: 25px;
    margin-right: 30px;
}

@media (max-width: 1200px) {
    #rc-header-c {
        width: 90%;
        margin: 50px 5%;
    }

    .rc-input-section-c {
        width: 295px;
        margin: auto;
    }

    #rc-input-c input {
        width: 275px;
    }

    #rc-input-c span {
        margin-left: 10px;
    }

    #rc-input-c {
        text-align: left;
    }

    .rc-input-section-c {
        margin-bottom: 65px;
    }

    #rc-input-submit-button {
        margin: 0 auto;
    }

    #rc-form-c {
        text-align: center;
    }

    #rc-chart-c {
        margin-top: 30px;
    }

    #rc-output-c {
        width: fit-content;
        margin: 25px auto;
        padding: 25px;
    }

    .rc-section-title-container {
        margin-bottom: 20px;
    }

    .rc-text-input-c {
        height: 56px;
        margin-bottom: 30px;
    }

    .rc-section-title {
        margin-left: 0 !important;
    }

    #rc-output-c {
        display: flex;
        flex-direction: column;
        text-align: top;
        flex-grow: 1;
        text-align: center;
        margin-top: 44.5px;
        gap: 20px;
    }

    .rc-retire-text {
        text-align: left;
        background-color: var(--color-primary-050);
        padding: 15px 25px;
        margin: 0;
    }
}

@media (min-width: 1201px) {
    #rc-c > * {
        margin: 50px 5%;
        margin-top: 0;
    }

    #rc-input-c input {
        width: 225px;
        margin-bottom: 5px;
    }

    #rc-input-c span {
        display: inline-block;
        width: 225px;
        margin-left: 10px;
    }

    #rc-input-c {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 600px;
        margin-bottom: 50px;
    }

    .rc-text-input-c {
        display: inline-block;
        height: 56px;
        margin-bottom: 25px;
        margin-right: 30px;
    }

    #rc-form-c {
        display: inline-block;
    }

    #rc-input-submit-button {
        width: 175px;
    }

    #rc-input-submit-button {
        margin: 0 auto;
    }

    #rc-chart-c {
        margin: 0;
        padding: 50px;
        background-color: var(--color-background);
    }

    #rc-output-c {
        display: flex;
        flex-direction: column;
        text-align: top;
        flex-grow: 1;
        text-align: center;
        margin-top: 44.5px;
        gap: 20px;
    }

    #rc-output-c p {
        background-color:var(--color-surface);
        color:var(--color-text-secondary);
    }

    .rc-retire-text {
        text-align: left;
        background-color: var(--color-primary-050);
        padding: 40px 60px;
        margin: 0;
    }

    #rc-output-age {

    }

    #rc-input-output-c {
        display:flex;
    }

    .rc-input-group {
        display: flex;
        flex-wrap: wrap;
    }

    .rc-section-title {
        margin-bottom: 25px;
        margin-left: 0 !important;
    }
}

