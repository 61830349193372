









































































@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/textfield";
@include textfield.core-styles;

.mdc-text-field {
    @include textfield.disabled-fill-color(#F3F4F7);
    @include textfield.ink-color(var(--color-text-primary));
    @include textfield.label-color(var(--color-text-primary)); //color del label que esta en el input y que luego se pone arriba
    @include textfield.placeholder-color(var(--color-text-primary));
    @include textfield.disabled-ink-color(var(--color-text-primary));
    @include textfield.outline-color(var(--color-text-primary));//color del borde cuando no esta seleccionado
    @include textfield.outline-color(var(--color-text-primary));
    @include textfield.hover-outline-color(var(--color-text-primary));  // Add this line
    --mdc-theme-primary: #0E5F85FF; //con esta linea cambiamos el borde del input cuando esta seleccionado.
}
